.light{
  --text-color: var(--blue);
  --bg-color: var(--beige);
}

.dark{
  --text-color: var(--white);
  --bg-color: var(--black);
}

.standard{
  --height-carousel: 594px;
}

.compact{
  --height-carousel: 380px;
}

.carousel {
  height: var(--height-carousel);
  width: 100%;
  overflow: hidden;
  z-index: 1;
  background: var(--bg-color);
  position: relative;
}

.carouselLightbox {
  position: relative;
  width: 100%;
  overflow: hidden;
  z-index: 1;
  background: var(--bg-color);
  grid-column: full-start / full-end !important;
}

.carouselContainer {
  display: flex;
  gap: var(--spacing-16);
  grid-column: full-start / full-end;
}

.carouselWrapper{
  grid-column: full-start / full-end !important;
}

.carouselSlide {
  position: relative;
  display: block;
  max-width: 95%;
  width: fit-content;
  height: var(--height-carousel);
  flex: 0 0 auto;
}

.carouselSlide img{
  height: 100%;
}

.navigationContainer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.carouselNav {
  display: flex;
  flex-flow: row nowrap;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;
}

@media (max-width: 1200px) {
  .standard{
    --height-carousel: 440px;
  }
  
  .compact{
    --height-carousel: 318px;
  }
}
@media (max-width: 480px) {
  .standard{
    --height-carousel: 300px;
  }
  
  .compact{
    --height-carousel: 175px;
  }
}