.carouselButton {
  --size-button: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--size-button);
  height: var(--size-button);
  cursor: pointer;
  transition: color 0.2s ease-in-out;
}

.accent.carouselButton {
  background-color: var(--yellow-500);
  color: var(--white);
}

.accent.carouselButton:hover {
  color: var(--beige);
}

.accent.carouselButton:active {
  color: var(--white);
}

.light.carouselButton {
  color: var(--blue);
  background-color: var(--white);
}

.light.carouselButton:hover {
  color: var(--black);
}

.light.carouselButton:active {
  color: var(--blue);
}

.carouselButton:disabled {
  cursor: default !important;
  pointer-events: none;
  color: var(--grey);
}

.carouselButton svg {
  --size: 1.725rem;
  width: var(--size);
  height: var(--size);
}

.carouselButton:disabled svg {
  opacity: 0.3;
}
